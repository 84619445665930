<template>
  <div class="advertisers">
    <advertisersHero/>
    <advertisersFacts/>
    <advertisersTypes/>
    <advertisersQuestions/>
    <partnersReviews/>
  </div>
</template>

<script>
export default {
  name: "Advertisers",
  components: {
    AdvertisersHero: () => import('@/components/pages/advertisers/AdvertisersHero.vue'),
    AdvertisersFacts: () => import('@/components/pages/advertisers/AdvertisersFacts.vue'),
    AdvertisersTypes: () => import('@/components/pages/advertisers/AdvertisersTypes.vue'),
    AdvertisersQuestions: () => import('@/components/pages/advertisers/AdvertisersQuestions.vue'),
    PartnersReviews: () => import('@/components/common/PartnersReviews.vue'),
  }
};
</script>